import { useMutation } from '@tanstack/react-query';
import { openLoading, closeLoading } from '../../../../components/alert/hooks/useAlert';
import { POST, PUT, POST_COUPON_VERIFY, PUT_COUPON_USED_UPDATE } from '../../../../services';
import { openAlert } from '../../../../components/alert/hooks';
import { MONITOR_LARK } from '../../../../helpers/lark';

export const useUpdateCouponUsedPut = (props) => {
  const { onVerifySuccessCallBack, onVerifyErrorCallBack, onUsedSuccessCallBack } = props;

  const couponVerifyPost = useMutation({
    mutationFn: async (data) => {
      const response = await POST(POST_COUPON_VERIFY, data);

      await new Promise((resolve) => setTimeout(resolve, 200));
      closeLoading();
      if (response?.success) return response?.result?.item;
    },
    onSuccess: async (data) => {
      onVerifySuccessCallBack(data);
    },
    onError: (error) => {
      closeLoading();
      onVerifyErrorCallBack(error);
      MONITOR_LARK({
        module: 'หน้าใช้คูปอง',
        message: `${POST_COUPON_VERIFY} : Verify Coupon Error!!`,
        error,
      });
    },
  });

  const couponUsedPut = useMutation({
    mutationFn: async (data) => {
      const response = await PUT(PUT_COUPON_USED_UPDATE, data);

      await new Promise((resolve) => setTimeout(resolve, 200));
      closeLoading();
      if (response?.success) return response?.result?.item;
      else {
        throw new Error(response?.message);
      }
    },
    onSuccess: async (data) => {
      onUsedSuccessCallBack(data);
    },
    onError: (error) => {
      closeLoading();
      openAlert({ model: 'modal', type: 'error', message: error?.message });
      MONITOR_LARK({
        module: 'หน้าใช้คูปอง',
        message: `${POST_COUPON_VERIFY} : Confirm Coupon Error!!`,
        error,
      });
    },
  });

  const couponHook = (data, action) => {
    openLoading();
    if (action.toLowerCase() === 'used') {
      return couponUsedPut.mutate(data);
    } else {
      return couponVerifyPost.mutate(data);
    }
  };

  return {
    couponHook,
  };
};
