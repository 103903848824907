import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Typography, Row, Spin } from 'antd';
import liff from '@line/liff';
import { ModalTheme } from '../../../../components/modal';
import { RenderForm } from '../../../../components/forms';
import { ContainerButton } from '../../../../styles/global-style';
import { ButtonTheme } from '../../../../components/buttons';
import { TextSmall } from '../../../../components/text';
import { FORM_SUPERMEMBER } from '../forms';
import { promotionQuery } from '../../../../services/react-query/optionMasterQuery';
import { useSelector } from 'react-redux';
import { useInsertSuper } from '../hooks/useInsertSuper';
import { insertSuperMember } from '../../main';
import { env } from '../../../../utils/env/config';

export const SuperMember = ({ open, onToggle, onSuperMemberSuccess }) => {
  const { control, setValue, getValues, handleSubmit } = useForm();
  const { data: promotionList, isLoading: promotionLoading } = promotionQuery();

  const userProfile = useSelector((state) => state.info);
  const { mutate: onInsertSupermember } = useInsertSuper({
    onSuccessCallBack: () => {
      onToggle({ defaultValue: '' });
      onSuperMemberSuccess({ isSuperMemberSuccess: true });
    },
  });

  useEffect(() => {
    if (userProfile) {
      setValue('telephone', userProfile?.telephone);
    }
  }, []);

  const onSubmit = (value) => {
    const formData = insertSuperMember(value);
    onInsertSupermember(formData);
  };
  const onClickLinkSupermember = () => {
    liff.openWindow({ url: env.url_super_member, external: true });
  };

  return (
    <Spin spinning={promotionLoading}>
      <ModalTheme title="ลงทะเบียน Super member" open={open} onToggle={onToggle}>
        <div style={{ textAlign: 'center', width: '100%' }}>
          <TextSmall text="สำหรับการสมัครสมาชิกผ่าน Focus Store เท่านั้น" color="red" />
        </div>
        <div style={{ textAlign: 'center', width: '100%' }}>
          <Row justify="center">
            <Typography.Link onClick={() => onClickLinkSupermember()} underline>
              หากไม่ได้ซื้อผ่าน Focus Store
            </Typography.Link>
          </Row>
          <Row justify="center">
            <Typography.Link onClick={() => onClickLinkSupermember()} underline>
              สนใจสมัคร lifetime คลิก
            </Typography.Link>
          </Row>
        </div>

        <RenderForm
          control={control}
          setValue={setValue}
          forms={FORM_SUPERMEMBER({ promotionList, defaultValues: userProfile })}
          getValues={getValues}
        />

        <ContainerButton right>
          <ButtonTheme useFor="OK" onClick={handleSubmit(onSubmit)} />
          <ButtonTheme useFor="CANCEL" onClick={onToggle} />
        </ContainerButton>
      </ModalTheme>
    </Spin>
  );
};
