import { useMutation } from '@tanstack/react-query';
import { GET, GET_STORE_BY_ID } from '../../../../services';
import { openAlert } from '../../../../components/alert/hooks';

export const useGetStore = ({ storeId }) =>
  useMutation({
    mutationFn: async () => {
      const response = await GET(GET_STORE_BY_ID(storeId));
      return response;
    },
    onSuccess: (data) => {
      openAlert({
        type: 'success',
        message: data.message,
        model: 'message',
      });
    },
    onError: (error) => {
      openAlert({
        type: 'error',
        message: error.message,
        model: 'message',
      });
    },
  });
