import axios from 'axios';
import moment from 'moment';
import { env } from '../utils/env/config';

const TIMEOUT_MS = 5000;
const DATE_FORMAT = 'DD-MM-YYYY HH:mm:ss';

export const MONITOR_LARK = async ({ module, message, error = {} }) => {
  try {
    const section = `${env.title} [${env.node}]`;
    const content = createLarkMessage({ section, module, message, error });

    const response = await axios({
      url: env.lark_bot_monitor_error_url,
      method: 'POST',
      timeout: TIMEOUT_MS,
      withCredentials: false,
      headers: {
        'Content-Type': 'application/json',
      },
      data: content,
    });

    return response.data;
  } catch (err) {
    console.error('MONITOR_LARK Error:', err);
    throw err;
  }
};

const formatError = (error) => {
  if (error instanceof Error) {
    return `${error.name} ${error.message} \n INFO : ${error.stack}`;
  }
  return typeof error === 'object' ? JSON.stringify(error, null, 4) : String(error);
};

const createLarkMessage = ({ section, module, message, error }) => {
  const timestamp = moment().format(DATE_FORMAT);
  const errorText = formatError(error);

  return {
    section: section,
    module: module,
    message: message,
    errorText: `${timestamp} : ${errorText}`,
  };
};
