export const FORM_INPUT_CLAIM_INSERT = ({ warranty, optionsBreakdowns }) => {
  return [
    {
      name: 'warrantyCode',
      label: 'รหัสรับประกัน',
      type: 'INPUT',
      span: 12,
      disabled: true,
      defaultValue: warranty?.warrantyCode || '',
      rules: {
        required: {
          value: true,
          message: 'ไม่พบรหัสรับประกันสินค้ากรุณาออกแล้วเข้าใหม่อีกครั้ง',
        },
      },
    },
    {
      name: 'brandMobile',
      label: 'ยี่ห้อมือถือ',
      type: 'INPUT',
      span: 12,
      disabled: true,
      defaultValue: warranty?.mobileBrandName || '',
    },
    {
      name: 'modelMobile',
      label: 'รุ่นมือถือ',
      type: 'INPUT',
      span: 12,
      disabled: true,
      defaultValue: warranty?.mobileModelName || '',
    },
    {
      name: 'breakdownId',
      label: 'อาการเสีย',
      type: 'DROPDOWN_TWO',
      placeholder: 'เลือกอาการเสีย',
      span: 12,
      properties: {
        options: optionsBreakdowns || [],
      },
      rules: {
        required: {
          value: true,
          message: 'กรุณาเลือกอาการเสีย',
        },
      },
      style: { width: '100%', height: '100%', minHeight: '32px' },
    },
    {
      name: 'detail1',
      label: 'รายละเอียดเพิ่มเติม',
      type: 'INPUT',
      placeholder: 'กรอกรายละเอียดเพิ่มเติม',
      span: 12,
      maxLength: 250,
    },
  ];
};

export const FORM_INPUT_CLAIM_ADDRESS = ({
  optionsOldAddress,
  watch,
  optionsProvinces,
  optionsDistricts,
  optionsSubDistricts,
  onZipCodeEnter,
  onProvinceSelected,
  onDistrictSelected,
  onOldAddressSelected,
  onNewAddressRaioSelected,
}) => {
  return [
    {
      name: 'claimImage',
      label: 'ภาพสินค้าเคลม',
      type: 'UPLOAD_TWO',
      span: 12,
      rules: {
        required: {
          value: true,
          message: 'กรุณาถ่ายภาพหรือเลือกภาพสินค้าที่ต้องการเคลม',
        },
      },
    },
    {
      name: 'isCreateNewAddress',
      label: 'ที่อยู่จัดส่ง',
      type: 'RADIO',
      span: 12,
      defaultValue: false,
      properties: {
        options: [
          { label: 'ที่อยู่เดิม', value: false },
          { label: 'เพิ่มที่อยู่ใหม่', value: true },
        ],
      },
      onChange: (data) => {
        onNewAddressRaioSelected(data.target.value);
      },
    },
    ...(!watch('isCreateNewAddress')
      ? [
          {
            name: 'oldAddresses',
            label: 'เลือกที่อยู่',
            type: 'DROPDOWN_TWO',
            placeholder: 'เลือกที่อยู่',
            span: 12,
            properties: {
              options: optionsOldAddress || [],
            },
            onChange: (data, item) => {
              onOldAddressSelected(item.value);
            },
            style: { width: '100%', height: '100%', minHeight: '32px' },
          },
        ]
      : []),
    {
      name: 'zipCode',
      label: 'รหัสไปรษณีย์',
      type: 'INPUT',
      placeholder: 'กรอกรหัสไปรษณีย์',
      span: 12,
      maxLength: 5,
      disabled: !watch('isCreateNewAddress'),
      rules: {
        required: {
          value: true,
          message: 'กรุณากรอกรหัสไปรษณีย์',
        },
      },
      onEnter: (data) => {
        onZipCodeEnter(data);
      },
    },
    {
      name: 'provinceId',
      label: 'จังหวัด',
      type: 'DROPDOWN_TWO',
      placeholder: 'เลือกจังหวัด',
      span: 12,
      disabled: !watch('isCreateNewAddress'),
      properties: {
        options: optionsProvinces || [],
      },
      rules: {
        required: {
          value: true,
          message: 'กรุณาเลือกจังหวัด',
        },
      },
      onChange: (data, item) => {
        onProvinceSelected(item.value);
      },
    },
    {
      name: 'districtId',
      label: 'เขต/อำเภอ',
      type: 'DROPDOWN_TWO',
      placeholder: 'เลือกเขต/อำเภอ',
      span: 12,
      disabled: !watch('isCreateNewAddress'),
      properties: {
        options: optionsDistricts || [],
      },
      rules: {
        required: {
          value: true,
          message: 'กรุณาเลือกเขต/อำเภอ',
        },
      },
      onChange: (data, item) => {
        onDistrictSelected(item.value);
      },
    },
    {
      name: 'subDistrictId',
      label: 'แขวง/ตำบล',
      type: 'DROPDOWN_TWO',
      placeholder: 'เลือกแขวง/ตำบล',
      span: 12,
      disabled: !watch('isCreateNewAddress'),
      properties: {
        options: optionsSubDistricts || [],
      },
      rules: {
        required: {
          value: true,
          message: 'กรุณาเลือกแขวง/ตำบล',
        },
      },
    },
    {
      name: 'other',
      label: 'รายละเอียดที่อยู่ (บ้านเลขที่/ถนน/หมู่ที่/หมู่บ้าน)',
      type: 'INPUT_AREA',
      placeholder: 'กรอกรายละเอียดที่อยู่',
      span: 12,
      maxLength: 255,
      rules: {
        required: {
          value: true,
          message: 'กรุณารายละเอียดที่อยู่',
        },
      },
    },
  ];
};
