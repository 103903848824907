import { useMutation } from '@tanstack/react-query';
import { POST, POST_SUPERMEMBER } from '../../../../services';
import { openAlert } from '../../../../components/alert/hooks';
import { openLoading, closeLoading } from '../../../../components/alert/hooks/useAlert';
import { MONITOR_LARK } from '../../../../helpers/lark';

export const useInsertSuper = (props) => {
  const { onSuccessCallBack } = props;
  return useMutation({
    mutationFn: async (formData) => {
      openLoading();
      const response = await POST(POST_SUPERMEMBER(), formData);
      closeLoading();
      return response.result;
    },
    onSuccess: () => {
      openAlert({
        model: 'message',
        type: 'success',
        message: 'ลงทะเบียน Super Member สำเร็จ',
      });
      onSuccessCallBack();
    },
    onError: (err) => {
      openAlert({ model: 'modal', type: 'error', message: err?.message });
      MONITOR_LARK({
        module: 'ลงทะเบียน Super Member',
        message: `${POST_WARRANTY()} : ลงทะเบียน Super Member ล้มเหลวล!!`,
        error: err,
      });
    },
  });
};
