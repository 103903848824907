export const FOMR_INPUT_REGISTER = ({
  channelBuyType,
  channelBuyList,
  productOptions,
  brandOptions,
  modelOptions,
}) => {
  const span = 24;
  const SCAN_QR = [
    {
      name: 'storeId',
      label: 'รหัสหรือ QRCode โฟกัส shop',
      type: 'INPUT_QR',
      span,
    },
  ];
  return [
    {
      name: 'warrantyNumberCode',
      label: 'รหัสรับประกัน',
      type: 'INPUT',
      disabled: true,
      span,
    },
    {
      name: 'fullName',
      label: 'ชื่อ-นามสกุล (Name - Surname)',
      type: 'INPUT',
      rules: {
        required: true,
      },
      span,
    },
    {
      name: 'telephone',
      label: 'หมายเลขโทรศัพท์มือถือ (Mobile phone)',
      type: 'INPUT',
      disabled: true,
      span,
    },
    {
      name: 'channelBuyId',
      label: 'ซื้อผ่านช่องทาง (Purchase channel)',
      type: 'DROPDOWN_TWO',
      properties: {
        options: channelBuyList || [],
      },
      rules: {
        required: true,
      },
      span,
      style: { width: '100%', height: '100%', minHeight: '32px' },
    },
    ...(channelBuyType === 'FOCUS' || channelBuyType === 'STORE' ? SCAN_QR : []),
    {
      name: 'mobileBrandId',
      label: 'ยี่ห้อโทรศัพท์ (Mobile phone brand)',
      type: 'DROPDOWN_TWO',
      properties: {
        options: brandOptions || [],
      },
      rules: {
        required: true,
      },
      span,
      style: { width: '100%', height: '100%', minHeight: '32px' },
    },
    {
      name: 'mobileModelId',
      label: 'รุ่นโทรศัพท์ (Mobile phone model)',
      type: 'DROPDOWN_TWO',
      properties: {
        options: modelOptions || [],
      },
      rules: {
        required: true,
      },
      span,
      style: { width: '100%', height: '100%', minHeight: '32px' },
    },
    {
      name: 'mapProductId',
      label: 'สินค้า',
      type: 'DROPDOWN_TWO',
      properties: {
        options: productOptions || [],
      },
      rules: {
        required: true,
      },
      span,
      style: { width: '100%', height: '100%', minHeight: '32px' },
    },
    {
      name: 'text',
      type: 'LABEL',
      span,
    },
  ];
};

export const FORM_COMFIRM = () => {
  return [
    {
      name: 'checkBox',
      label: 'ฉันได้ยอมรับ',
      type: 'CHECKBOX',
      properties: {
        options: [],
      },
      rules: {
        required: true,
      },
      span: 8,
    },
  ];
};

export const FORM_SUPERMEMBER = ({ promotionList, defaultValues }) => {
  const span = 24;
  return [
    {
      name: 'invNo',
      label: 'เลขที่ใบเสร็จ',
      type: 'INPUT',
      rules: {
        required: true,
      },
      span,
    },
    {
      name: 'storeId',
      label: 'ร้านค้า',
      type: 'INPUT_QR',
      disabled: true,
    },
    {
      name: 'promotionId',
      label: 'ประเภทสมาชิก',
      type: 'DROPDOWN_TWO',
      properties: {
        options: promotionList || [],
      },
      rules: {
        required: true,
      },
      span,
    },
    {
      name: 'telephone',
      label: 'หมายเลขโทรศัพท์มือถือ (Mobile phone)',
      type: 'INPUT',
      rules: {
        required: true,
      },
      disabled: true,
      span,
    },
    {
      name: 'birthDate',
      label: 'วันเกิด',
      type: 'DATE_PICKER',
      span,
      defaultValue: defaultValues?.birthday,
      rules: {
        required: true,
      },
    },
    {
      name: 'invImage',
      label: 'ภาพใบเสร็จ',
      type: 'UPLOAD_TWO',
      span,
      maxCount: 1,
      rules: {
        required: true,
      },
      accept: 'image/*',
    },
  ];
};

export const FORM_SUPER_MEMBER_STORE = () => {
  const span = 12;
  return [
    {
      name: 'packageImage',
      label: 'รูปภาพแพ็คเกจ',
      type: 'UPLOAD_TWO',
      span,
      maxCount: 1,
      rules: {
        required: {
          value: true,
          message: 'กรุณาแนบรูปภาพแพ็คเกจ',
        },
      },
    },
    {
      name: 'invImage',
      label: 'รูปภาพใบเสร็จ',
      type: 'UPLOAD_TWO',
      span,
      maxCount: 1,
      rules: {
        required: {
          value: true,
          message: 'กรุณาแนบรูปภาพใบเสร็จ',
        },
      },
    },
  ];
};
