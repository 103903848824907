import React, { Fragment, useState, useRef, useEffect } from 'react';
import { CameraFilled, PictureFilled } from '@ant-design/icons';
import { useController } from 'react-hook-form';
import { Button, Upload, Modal, Row, Col, Flex } from 'antd';
import { Required, RowComponent } from '../../../styles/global-style';
import { TextXSMall, TextInputLabel } from '../../text';
import { renderTypeError } from '../index.jsx';
import { closeLoading, openLoading } from '../../alert/hooks/useAlert';
import heic2any from 'heic2any';

const UploadFilesTwo = ({ control, item, setValue, getValues }) => {
  const { rules, name, defaultValue, label, disabled, dataCy, ...propsInput } = item;
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [cameraStream, setCameraStream] = useState(null);
  const [showCamera, setShowCamera] = useState(false);
  const videoRef = useRef(null);

  const { field, fieldState } = useController({
    control,
    name,
    rules,
    defaultValue,
  });
  const { error } = fieldState;
  const { value } = field;

  useEffect(() => {
    if (videoRef.current && cameraStream) {
      videoRef.current.srcObject = cameraStream;
    }
  }, [cameraStream]);

  useEffect(() => {
    return () => {
      if (cameraStream) {
        cameraStream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [cameraStream]);

  /**
   * Convert HEIF/HEIC images to JPEG before processing
   * @param {File} file - The image file to convert if needed
   * @returns {Promise<File>} - A promise that resolves to the converted file
   */

  const convertHeicToJpeg = async (file) => {
    if (
      file.name.toLowerCase().endsWith('.heic') ||
      file.name.toLowerCase().endsWith('.heif') ||
      file.type === 'image/heic' ||
      file.type === 'image/heif'
    ) {
      try {
        const jpegBlob = await heic2any({
          blob: file,
          toType: 'image/jpeg',
          quality: 0.8,
        });

        const originalName = file.name.substring(0, file.name.lastIndexOf('.')) || file.name;
        const jpegFile = new File([jpegBlob], `${originalName}.jpg`, {
          type: 'image/jpeg',
          lastModified: Date.now(),
        });

        return jpegFile;
      } catch (error) {
        console.error('Error converting HEIC:', error);
        return file;
      }
    }
    return file;
  };

  const resizeImage = async (file, maxSizeKB = 250) => {
    if (file.size <= maxSizeKB * 1024 && file.type === 'image/jpeg') {
      return file;
    }

    return new Promise((resolve) => {
      const img = new Image();
      const reader = new FileReader();

      reader.onload = (e) => {
        img.src = e.target.result;

        img.onload = () => {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;
          let quality = 0.9;
          const maxSize = maxSizeKB * 1024;

          const resizeAndCheck = () => {
            canvas.width = width;
            canvas.height = height;

            const ctx = canvas.getContext('2d');
            if (file.type !== 'image/jpeg') {
              ctx.fillStyle = '#FFFFFF';
              ctx.fillRect(0, 0, width, height);
            }
            ctx.drawImage(img, 0, 0, width, height);

            canvas.toBlob(
              (blob) => {
                if (blob.size <= maxSize) {
                  const originalName =
                    file.name.substring(0, file.name.lastIndexOf('.')) || file.name;
                  const resizedFile = new File([blob], `${originalName}.jpg`, {
                    type: 'image/jpeg',
                    lastModified: Date.now(),
                  });
                  resolve(resizedFile);
                } else {
                  if (quality > 0.5) {
                    quality -= 0.1;
                    resizeAndCheck();
                  } else {
                    const scaleFactor = Math.sqrt(maxSize / blob.size) * 0.9;
                    width = Math.floor(width * scaleFactor);
                    height = Math.floor(height * scaleFactor);
                    resizeAndCheck();
                  }
                }
              },
              'image/jpeg',
              quality,
            );
          };

          resizeAndCheck();
        };
      };

      reader.readAsDataURL(file);
    });
  };

  const onChange = async (event) => {
    try {
      const file = event.fileList[0]?.originFileObj;
      if (file) {
        openLoading();
        // First convert HEIC if needed
        const convertedFile = await convertHeicToJpeg(file);
        // Then resize the image
        const resizedFile = await resizeImage(convertedFile);

        const base64 = await getBase64(resizedFile);
        const newFile = {
          uid: `-${Date.now()}`,
          name: resizedFile.name,
          status: 'done',
          url: base64,
          originFileObj: resizedFile,
        };

        setValue(name, [newFile]);
        closeLoading();
      }
    } catch (error) {
      closeLoading();
      Modal.error({
        title: 'โหลดรูปภาพล้มเหลว!!',
        content: 'กรุณาเลือกรูปภาพใหม่',
      });
    }
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (err) => reject(err);
    });

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const startCamera = async () => {
    try {
      if (cameraStream) {
        cameraStream.getTracks().forEach((track) => track.stop());
      }
      const cameraConfig = {
        video: { facingMode: 'environment' },
      };
      setShowCamera(true);
      const stream = await navigator.mediaDevices.getUserMedia(cameraConfig);

      setCameraStream(stream);

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.onloadedmetadata = () => {
          videoRef.current.play().catch((e) => console.error('Error playing video:', e));
        };
      }
    } catch (err) {
      Modal.error({
        title: 'ไม่สามารถเข้าถึงกล้องได้',
        content: 'กรุณาตรวจสอบว่าเบราว์เซอร์ได้รับอนุญาตให้เข้าถึงกล้องหรือไม่',
      });
      setShowCamera(false);
    }
  };

  const stopCamera = () => {
    if (cameraStream) {
      cameraStream.getTracks().forEach((track) => {
        track.stop();
      });
      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
      setCameraStream(null);
    }
    setShowCamera(false);
  };

  const capturePhoto = () => {
    if (!videoRef.current) return;

    const video = videoRef.current;
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const ctx = canvas.getContext('2d');

    ctx.save();
    ctx.drawImage(video, 0, 0);
    ctx.restore();

    canvas.toBlob(async (blob) => {
      const file = new File([blob], `camera-capture-${Date.now()}.jpg`, { type: 'image/jpeg' });
      // Resize captured photo
      const resizedFile = await resizeImage(file);
      const base64 = await getBase64(resizedFile);

      const newFileList = [
        {
          uid: `-${Date.now()}`,
          name: resizedFile.name,
          status: 'done',
          url: base64,
          originFileObj: resizedFile,
        },
      ];

      setValue(name, newFileList);
      stopCamera();
    }, 'image/jpeg');
  };

  const uploadStyle = {
    uploadBox: {
      width: '35vw', // Set fixed width
      height: '35vw', // Set fixed height
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px dashed #d9d9d9',
      borderRadius: '8px',
      backgroundColor: '#fafafa',
      textAlign: 'center',
      cursor: 'pointer',
      transition: 'all 0.3s',
      margin: '10px auto', // Remove extra margins
      padding: '10px', // Adjust padding for better alignment
      '&:hover': {
        borderColor: '#1890ff',
        backgroundColor: '#f0f5ff',
      },
    },
    iconContainer: {
      fontSize: '36px',
      color: '#40a9ff',
      marginBottom: '4px',
    },
    text: {
      color: '#666',
      fontSize: '14px',
    },
    preview: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& img': {
        maxWidth: '100%',
        maxHeight: '100%',
      },
    },
  };

  return (
    <Fragment>
      <RowComponent>
        {label && <TextInputLabel text={label} />}
        {rules && rules.required && <Required>*</Required>}
      </RowComponent>

      <Row gutter={24}>
        {/* Camera Upload Box */}
        <Col span={12}>
          <div
            style={uploadStyle.uploadBox}
            onClick={startCamera}
            className="hover:border-blue-400 hover:bg-blue-50"
          >
            <div style={uploadStyle.iconContainer}>
              <CameraFilled style={{ fontSize: '36px', color: '#40a9ff' }} />
            </div>
            <div style={uploadStyle.text}>ถ่ายภาพ</div>
            <div style={{ fontSize: '12px', color: '#999', marginTop: '4px' }}>
              ใช้กล้องถ่ายภาพโดยตรง
            </div>
          </div>
        </Col>

        {/* File Upload Box */}
        <Col span={12}>
          <Upload
            id={name}
            beforeUpload={() => false}
            listType="picture"
            disabled={disabled || false}
            maxCount={1}
            accept="image/*"
            onPreview={handlePreview}
            fileList={getValues(name)}
            onChange={onChange}
            showUploadList={false}
            {...propsInput}
          >
            <div style={uploadStyle.uploadBox} className="hover:border-blue-400 hover:bg-blue-50">
              <div style={uploadStyle.iconContainer}>
                <PictureFilled style={{ fontSize: '36px', color: '#40a9ff' }} />
              </div>
              <div style={uploadStyle.text}>แนบไฟล์</div>
              <div style={{ fontSize: '12px', color: '#999', marginTop: '4px' }}>
                เลือกไฟล์จากอุปกรณ์
              </div>
            </div>
          </Upload>
        </Col>
      </Row>

      {/* Preview Area */}
      {value?.length > 0 && (
        <Flex style={uploadStyle.preview} justify="center" align="center">
          <Upload
            listType="picture-card"
            fileList={getValues(name)}
            onPreview={handlePreview}
            onRemove={() => setValue(name, [])}
            showUploadList={{ showPreviewIcon: true, showRemoveIcon: true }}
            style={{ width: '100%', height: '100%' }}
          />
        </Flex>
      )}

      {/* Preview Modal */}
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img alt="preview" style={{ width: '100%' }} src={previewImage} />
      </Modal>

      {/* Camera Modal */}
      <Modal
        open={showCamera}
        title="ถ่ายภาพ"
        onCancel={stopCamera}
        width={800}
        destroyOnClose={true}
        footer={[
          <Button key="cancel" onClick={stopCamera}>
            ยกเลิก
          </Button>,
          <Button key="capture" type="primary" onClick={capturePhoto}>
            ถ่ายภาพ
          </Button>,
        ]}
      >
        <div style={{ width: '100%', maxWidth: '800px', margin: '0 auto' }}>
          <video
            ref={videoRef}
            autoPlay
            playsInline
            style={{
              width: '100%',
              borderRadius: '8px',
              backgroundColor: '#000',
            }}
          />
        </div>
      </Modal>

      {error && (
        <div style={{ marginTop: '8px' }}>
          <TextXSMall text={renderTypeError(item, error)} color="red" />
        </div>
      )}
    </Fragment>
  );
};

export const MemoizedUploadTwo = React.memo(UploadFilesTwo);
