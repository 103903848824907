export const handleSubmitForm = (values, setIsSubmitting, mutateUpload) => {
  setIsSubmitting(true);

  const invImage = values.invImage && values.invImage.length > 0 ? values.invImage[0] : null;
  const packageImage =
    values.packageImage && values.packageImage.length > 0 ? values.packageImage[0] : null;

  const uploadData = {
    telephone: values.telephone,
    fullName: values.fullName,
    warrantyNumberCode: values.warrantyNumberCode,
    mobileBrandId: values.mobileBrandId,
    mobileModelId: values.mobileModelId,
    mapProductId: values.mapProductId,
    channelBuyId: values.channelBuyId,
    customerAccountCode: values.customerAccountCode,
    storeId: values.storeId,
    invImage,
    packageImage,
  };

  mutateUpload(uploadData);
};
