import { useMutation } from '@tanstack/react-query';
import { POST, POST_WARRANTY } from '../../../../services';
import { openAlert } from '../../../../components/alert/hooks';
import { InsertWarranty2 } from '../../main';
import { navigateReplaceTo } from '../../../../utils/navigation';
import { ROUTES_PATH } from '../../../../resources/routes-name';
import { openLoading, closeLoading } from '../../../../components/alert/hooks/useAlert';
import { MONITOR_LARK } from '../../../../helpers/lark';

export const useInsertWarranty = () =>
  useMutation({
    mutationFn: async (values) => {
      openLoading();
      const formData = new FormData();
      const payload = InsertWarranty2(values);

      Object.entries(payload).forEach(([key, value]) => {
        if (value !== null && value !== undefined) {
          formData.append(key, value);
        }
      });
      const response = await POST(POST_WARRANTY(), formData);

      closeLoading();
      return response;
    },
    onSuccess: () => {
      openAlert({
        model: 'message',
        type: 'success',
        message: 'ลงทะเบียนรับประกันสินค้าสำเร็จ',
      });
      navigateReplaceTo({ pathname: ROUTES_PATH.ROUTE_HOME.PATH });
    },
    onError: (err) => {
      console.log('err', err);
      closeLoading();
      const msgErr =
        err && err.message == 'NETWORK_ERROR'
          ? 'ลงทะเบียนรับประกันสินค้าไม่สำเร็จ \nหากท่านใช้ภาพจากการอัพโหลดลงทะเบียนไม่สำเร็จ\nให้ท่านเปลี่ยนมาใช้การถ่ายภาพแทน\nขออภัยในความไม่สะดวกค่ะ'
          : err.message;
      openAlert({
        model: 'message',
        type: 'error',
        message: msgErr,
      });
      MONITOR_LARK({
        module: 'หน้าลงทะเบียนรับประกัน',
        message: `${POST_WARRANTY()} : ลงทะเบียนรับประกันสินค้าไม่สำเร็จ!!`,
        error: err,
      });
    },
  });
